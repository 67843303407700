<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- <div>
      {{ this.rows }}
    </div> -->
    <!-- {{ material_code_gen }}
    {{ registration_number }} -->

    <div class="row justify-content-center">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">รับเข้าวัสดุ</p>
            <hr />
          </div>
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-6 row">
              <div class="col-12 col-md-2 mt-1">
                <b-button v-b-modal.modal-1 class="btn" variant="success" @click="carray"> เพิ่มข้อมูล </b-button>
              </div>
              <div class="col-12 col-md-4 mt-1">
                <b-button v-b-modal.modal-1 class="btn" variant="warning" @click="carray_register">
                  เพิ่มข้อมูลกลุ่มทะเบียนวัสดุ
                </b-button>
              </div>
            </div>

            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                  @input="s_data(searchTerm)"
                />

                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary">
                        <feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50" />
                      </b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <span>
                        <feather-icon icon="FileIcon" class="mr-50" />
                        Excel
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'edit'">
                  <b-button
                    v-b-modal="`modal-2${props.index}`"
                    variant="outline-secondary"
                    @click="edi_data_row(props.row)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- edit -->
                  <b-modal
                    :id="`modal-2${props.index}`"
                    cancel-variant="outline-secondary"
                    ok-variant="gradient-success"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    centered
                    title="แก้ไขรับเข้าวัสดุ"
                    size="lg"
                    no-close-on-backdrop
                    @ok="data_edit(props.row)"
                    @close="ccc_data()"
                    @cancel="ccc_data()"
                  >
                    <b-form>
                      <div>
                        <!-- {{ props.row }} -->
                        <div class="row">
                          <b-form-group class="col-12" label-cols="3" label-cols-lg="2" label="">
                            <template v-slot:label> ชื่อวัสดุ <span class="text-danger" /> </template>
                            <b-input v-model="props.row.m_name" disabled />
                          </b-form-group>
                          <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="">
                            <template v-slot:label> รหัสวัสดุ <span class="text-danger" /> </template>
                            <b-input v-model="props.row.m_id" disabled />
                          </b-form-group>
                          <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="ปีงบประมาณ:">
                            <template v-slot:label> ปีงบประมาณ <span class="text-danger" /> </template>
                            <b-form-input
                              type="text"
                              v-model="props.row.budget_year"
                              maxlength="4"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            />
                          </b-form-group>
                        </div>
                        <b-row>
                          <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="จำนวน:">
                            <template v-slot:label> จำนวน <span class="text-danger" /> </template>
                            <!-- <b-input disabled v-model="props.row.amount" /> -->
                            <b-input type="number" v-model="amount" disabled />
                          </b-form-group>

                          <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="หน่วยละ:">
                            <template v-slot:label> หน่วยนับ <span class="text-danger" /> </template>

                            <v-select
                              v-model="props.row.unittype"
                              placeholder="หน่วยนับ"
                              label="name"
                              :options="units"
                            />
                          </b-form-group>
                        </b-row>
                        <div class="row">
                          <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาจำนวนเต็ม:">
                            <template v-slot:label> ราคาจำนวนเต็ม <span class="text-danger" /> </template>
                            <b-input
                              v-model="unit_price"
                              class="form-control"
                              @change="max_unit_price"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            />
                          </b-form-group>
                          <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาต่อหน่วย:">
                            <template v-slot:label> ราคาต่อหน่วย <span class="text-danger" /> </template>
                            <b-form-input
                              v-model="unitprice"
                              @change="unitPrice_num"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            />
                          </b-form-group>
                          <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาจำนวนเต็มรวม VAT:">
                            <span v-if="vat_switch === true">
                              <b-form-input
                                v-model="unitprice_vat"
                                @change="max_unit_price_vat"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              />
                            </span>
                            <span v-else> <b-input v-model="unit_price" disabled /></span>
                          </b-form-group>
                          <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาต่อหน่วยรวม VAT:">
                            <span v-if="vat_switch === true">
                              <b-form-input
                                v-model="unit_price_vat"
                                @change="vat_chan"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              />
                            </span>
                            <span v-else> <b-input v-model="unitprice" disabled /></span>
                          </b-form-group>
                          <b-form-group class="col-6"> </b-form-group>

                          <b-form-group class="col-6">
                            <b-form-checkbox
                              v-model="vat_switch"
                              name="check-button"
                              checked="true"
                              switch
                              inline
                              @change="get_no_sum_vat(vat_switch)"
                            />ราคารวม VAT
                          </b-form-group>
                        </div>
                        <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                          <template v-slot:label> กลุ่มงาน <span class="text-danger"> </span> </template>
                          <v-select
                            v-model="work_gid"
                            placeholder="เลือกกลุ่มงาน"
                            label="work_gname"
                            :options="workGs"
                          />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="ผู้รับเข้า:">
                          <template v-slot:label> ผู้รับเข้า <span class="text-danger"> </span> </template>
                          <v-select v-model="middle" :options="alluser" label="firstname" placeholder="ผู้รับเข้า" />
                        </b-form-group>
                        <div class="row">
                          <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขทะเบียน:">
                            <template v-slot:label> เลขทะเบียน <span class="text-danger" /> </template>
                            <b-form-input v-model="props.row.material_code" disabled />
                          </b-form-group>
                          <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="วันที่รับ:">
                            <template v-slot:label> วันที่รับ <span class="text-danger" /> </template>
                            <v-date-picker
                              id="example-datepicker"
                              label="วันที่รับ:"
                              locale="th-TH"
                              :attributes="attributes"
                              v-model="date11"
                              @update:from-page="getHeaderInputDate"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <b-form-input :value="formatDate(inputValue, 'admitdate')" v-on="inputEvents" />
                              </template>
                              <template #header-title>
                                {{ headerInputDate }}
                              </template>
                            </v-date-picker>
                          </b-form-group>
                        </div>

                        <b-form-group label-cols="3" label-cols-lg="2" label="วิธีการจัดหา:">
                          <template v-slot:label> วิธีการจัดหา <span class="text-danger" /> </template>

                          <v-select v-model="props.row.supply_by" label="supply" :options="procurement" />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทเงิน:">
                          <template v-slot:label> ประเภทเงิน <span class="text-danger" /> </template>
                          <v-select v-model="props.row.budget_type" label="money_type" :options="moneytypes" />
                        </b-form-group>

                        <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทหลักฐาน:">
                          <template v-slot:label> ประเภทหลักฐาน <span class="text-danger" /> </template>
                          <!-- {{props.row}} -->
                          <v-select
                            :v-model="props.row.evidence_type ? props.row.evidence_type : ' '"
                            label="evidence"
                            placeholder="ประเภทหลักฐาน"
                            :options="evidenceTypes"
                          />
                        </b-form-group>
                        <div class="row"></div>
                        <div class="row">
                          <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขที่ใบส่งของ:">
                            <template v-slot:label> เลขที่ใบส่งของ <span class="text-danger" /> </template>

                            <b-form-input v-model="props.row.invoice_number" placeholder="เลขที่ใบส่งของ" />
                          </b-form-group>
                          <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="ลงวันที่:"
                            ><template v-slot:label> ลงวันที่ <span class="text-danger" /> </template>

                            <v-date-picker
                              id="example-datepicker"
                              label="วันที่รับ:"
                              locale="th-TH"
                              :attributes="attributes"
                              v-model="date22"
                              @update:from-page="getHeaderInputDate"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <b-form-input :value="formatDate(inputValue, 'invoice_date')" v-on="inputEvents" />
                              </template>
                              <template #header-title>
                                {{ headerInputDate }}
                              </template>
                            </v-date-picker>
                          </b-form-group>
                        </div>

                        <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อผู้ขาย:">
                          <template v-slot:label>
                            ชื่อผู้ขาย/รับจ้าง
                            <span class="text-danger"> </span>
                          </template>
                          <b-form-input v-model="props.row.vender" placeholder="ชื่อผู้ขาย/รับจ้าง" />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="เบอร์โทร:">
                          <template v-slot:label> เบอร์โทร <span class="text-danger"> </span> </template>

                          <b-input
                            :v-model="props.row.vender_phone ? props.row.vender_phone : '0'"
                            :value="props.row.vender_phone ? props.row.vender_phone : ''"
                            placeholder="เบอร์โทร"
                            maxlength="10"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="แฟกซ์:">
                          <!-- {{props.row.vender_fax}} -->
                          <template v-slot:label> แฟกซ์ <span class="text-danger"> </span> </template>
                          <b-form-input
                            :v-model="props.row.vender_fax == null ? '' : props.row.vender_fax"
                            :value="props.row.vender_fax == null ? '' : props.row.vender_fax"
                            placeholder="แฟกซ์"
                            maxlength="10"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="ที่อยู่ผู้ขาย/รับจ้าง:">
                          <template v-slot:label>
                            ที่อยู่ผู้ขาย/รับจ้าง
                            <span class="text-danger"> </span>
                          </template>
                          <b-form-textarea
                            :v-model="props.row.vender_address == null ? '' : props.row.vender_address"
                            :value="props.row.vender_address == null ? '' : props.row.vender_address"
                            placeholder="ที่อยู่ผู้ขาย/รับจ้าง"
                          />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="อีเมล:">
                          <template v-slot:label> อีเมล <span class="text-danger"> </span> </template>
                          <b-form-input
                            :v-model="props.row.vender_email == null ? '' : props.row.vender_email"
                            :value="props.row.vender_email == null ? '' : props.row.vender_email"
                            placeholder="อีเมล"
                          />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="หมายเลขภาษี:">
                          <template v-slot:label> หมายเลขภาษี <span class="text-danger"> </span> </template>
                          <b-form-input
                            :v-model="props.row.vender_taxnumber == null ? '' : props.row.vender_taxnumber"
                            :value="props.row.vender_taxnumber == null ? '' : props.row.vender_taxnumber"
                            placeholder="หมายเลขภาษี"
                          />
                        </b-form-group>
                        <b-form-group label-cols="3" label-cols-lg="2" label="รูปภาพ:">
                          <b-form-file
                            class="mb-1"
                            accept="image/*"
                            v-model="file1"
                            placeholder="เลือกไฟล์รูปภาพ"
                            drop-placeholder="Drop file here..."
                            multiple
                          />
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </span>

                <span v-else-if="props.column.field === 'Image'">
                  <b-button
                    v-b-modal="`modalimg${props.index}`"
                    variant="outline-primary"
                    data=""
                    @click="img_log(props.row)"
                  >
                    <input ref="refInputEl" type="file" class="d-none" />
                    <feather-icon icon="ImageIcon" />
                  </b-button>
                  <b-modal :id="`modalimg${props.index}`" title="รูปภาพ" ok-only ok-title="ปิด" no-close-on-backdrop>
                    <b-card-text class="text-center">
                      <div v-if="image_p != null">
                        <swiper
                          ref="mySwiper"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="swiperOptions"
                        >
                          <swiper-slide v-for="image_p in image_p" :key="image_p">
                            <b-img
                              width="250%"
                              height="250%"
                              src
                              :onerror="`fetch('${API}files/image/${image_p}',
                            {headers: {authorization:'${token}'}})
                            .then(r=>r.blob()).then(d=>
                            this.src=window.URL.createObjectURL(d))`"
                              class="mx-1"
                            />
                          </swiper-slide>
                          <div slot="button-next" class="swiper-button-next" />
                          <div slot="button-prev" class="swiper-button-prev" />
                          <div slot="pagination" class="swiper-pagination" />
                        </swiper>
                        <!-- <div v-for="image_p in image_p" :key="image_p">
                          <img
                            width="50%"
                            height="50%"
                            src
                            :onerror="`fetch('${API}files/image/${image_p}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                          />
                        </div> -->
                      </div>
                      <span v-else> ไม่มีรูปภาพ</span>
                    </b-card-text>
                  </b-modal>
                </span>

                <span v-else-if="props.column.field === 'del'">
                  <b-button variant="outline-primary" @click="del(props.row)">
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item>
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <validation-observer ref="observer" v-slot="{ passes }">
        <b-modal
          id="modal-1"
          cancel-variant="outline-secondary"
          ok-variant="gradient-success"
          ok-title="บันทึก"
          cancel-title="ยกเลิก"
          centered
          title="รับเข้าวัสดุ"
          size="lg"
          no-close-on-backdrop
          @ok.prevent="passes(postdata)"
        >
          <b-form>
            <div>
              <div class="row">
                <b-form-group
                  v-if="instan === 'on'"
                  class="col-12"
                  label-cols="3"
                  label-cols-lg="2"
                  label="เลขทะเบียน:"
                >
                  <v-select
                    v-model="registration_number"
                    label="material_code"
                    :options="registration_numbers"
                    placeholder="เลือกเลขทะเบียน"
                    @input="registration_(registration_number)"
                  />
                </b-form-group>

                <b-form-group class="col-12" label-cols="3" label-cols-lg="2" label="">
                  <template v-slot:label> กลุ่มวัสดุ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      v-model="m_gid"
                      placeholder="เลือกกลุ่มวัสดุ"
                      :options="selectedGs"
                      label="m_gname"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                      @input="changeRoute($event)"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกกลุ่มวัสดุ</p>
                    </div>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-12" label-cols="3" label-cols-lg="2" label="">
                  <template v-slot:label> ชื่อวัสดุ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      v-model="typeG"
                      :options="typeGs"
                      :state="errors.length > 0 ? false : null"
                      placeholder="เลือกชื่อวัสดุ"
                      label="m_name"
                      :clearable="false"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกชื่อวัสดุ</p>
                    </div>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="">
                  <template v-slot:label> รหัสวัสดุ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      id="idg"
                      v-model="typeG.m_id"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="รหัสวัสดุ"
                      disabled
                    />
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="ปีงบประมาณ:">
                  <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      id="ids"
                      v-model="budget_year"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="ปีงบประมาณ"
                      maxlength="4"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <b-row>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="จำนวน:">
                  <template v-slot:label> จำนวน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      type="number"
                      v-model="amount"
                      class="form-control"
                      placeholder="จำนวน"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="หน่วยนับ:">
                  <template v-slot:label> หน่วยนับ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      v-model="unittype"
                      :state="errors.length > 0 ? false : null"
                      placeholder="หน่วยนับ"
                      label="name"
                      :options="units"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกหน่วยนับ</p>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-row>
              <div class="row">
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคา:">
                  <template v-slot:label> ราคาจำนวนเต็ม <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      v-model="unit_price"
                      :state="errors.length > 0 ? false : null"
                      class="form-control"
                      placeholder="ราคา (จำนวนเต็ม)"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      @change="max_unit_price"
                    />
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาราคาต่อหน่วย:">
                  <template v-slot:label> ราคาต่อหน่วย <span class="text-danger"> *</span> </template>

                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      v-model="unitprice"
                      :state="errors.length > 0 ? false : null"
                      class="form-control"
                      placeholder="ราคา (ราคาต่อหน่วย)"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      @change="unitPrice_num"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label=" ราคาจำนวนเต็มรวม VAT :">
                  <span v-if="vat_switch === true">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-input
                        id="unit"
                        v-model="unitprice_vat"
                        class="form-control"
                        :raw="false"
                        :options="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="ราคาจำนวนเต็มรวม VAT"
                        @change="max_unit_price_vat"
                      />
                    </validation-provider>
                  </span>

                  <span v-else>
                    <b-input
                      id="unit"
                      class="form-control"
                      v-model="unit_price"
                      :options="number"
                      placeholder="ราคาต่อหน่วยรวม VAT"
                      disabled
                    />
                  </span>
                </b-form-group>
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาต่อหน่วยรวม VAT:">
                  <span v-if="vat_switch === true">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-input
                        id="unit"
                        v-model="unit_price_vat"
                        class="form-control"
                        :raw="true"
                        :options="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="ราคาต่อหน่วยรวม VAT"
                        @change="vat_chan"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      />
                    </validation-provider>
                  </span>
                  <span v-else>
                    <b-input
                      id="unit"
                      class="form-control"
                      v-model="unitprice"
                      :options="number"
                      placeholder="ราคาต่อหน่วยรวม VAT"
                      disabled
                    />
                  </span>
                </b-form-group>
                <b-form-group class="col-6"> </b-form-group>

                <b-form-group class="col-6">
                  <b-form-checkbox
                    v-model="vat_switch"
                    name="check-button"
                    checked="true"
                    switch
                    inline
                    @change="get_no_sum_vat(vat_switch)"
                  />ราคารวม VAT
                </b-form-group>
              </div>

              <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    v-model="work_gid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="เลือกกลุ่มงาน"
                    label="work_gname"
                    :options="workGs"
                    :clearable="false"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกกลุ่มงาน</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ผู้รับเข้า:">
                <template v-slot:label> ผู้รับเข้า <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    v-model="middle"
                    :options="alluser"
                    label="firstname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="ผู้รับเข้า"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">ผู้รับเข้า</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <div class="row">
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขทะเบียน:">
                  <template v-slot:label> เลขทะเบียน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      id="registration"
                      v-model="material_code"
                      :state="errors.length > 0 ? false : null"
                      placeholder="AUTO"
                      disabled
                    />
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="วันที่รับ:">
                  <template v-slot:label> วันที่รับ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-date-picker
                      id="example-datepicker"
                      label="วันที่รับ:"
                      locale="th-TH"
                      v-model="date1"
                      @update:from-page="getHeaderInputDate"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <b-form-input :value="formatDate(inputValue, 'admitdate')" v-on="inputEvents" />
                      </template>
                      <template #header-title>
                        {{ headerInputDate }}
                      </template>
                    </v-date-picker>
                    <!-- <b-form-datepicker
                      id="example-datepicker"
                      v-model="admitdate"
                      placeholder="วันที่รับ"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: 'numeric',
                      }"
                      locale="th-TH"
                      
                    /> -->
                  </validation-provider>
                </b-form-group>
              </div>

              <b-form-group label-cols="3" label-cols-lg="2" label="วิธีการจัดหา:">
                <template v-slot:label> วิธีการจัดหา <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    v-model="supply_by"
                    label="supply"
                    :options="procurement"
                    :state="errors.length > 0 ? false : null"
                    placeholder="เลือกวิธีการจัดหา"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกวิธีการจัดหา</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทเงิน:">
                <template v-slot:label> ประเภทเงิน <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    v-model="budget_type"
                    label="money_type"
                    :options="moneytypes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="เลือกประเภทเงิน"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกประเภทเงิน</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <div class="row"></div>
              <div class="row">
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขที่ใบส่งของ:"
                  ><template v-slot:label> เลขที่ใบส่งของ <span class="text-danger" /> </template>

                  <b-form-input v-model="delivery_invoice" placeholder="เลขที่ใบส่งของ" />
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="ลงวันที่:"
                  ><template v-slot:label> ลงวันที่ <span class="text-danger" /> </template>

                  <v-date-picker
                    id="example-datepicker_delivery_invoice_date"
                    label="ลงวันที่:"
                    locale="th-TH"
                    v-model="date2"
                    @update:from-page="getHeaderInputDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <b-form-input
                        :value="formatDate(inputValue, 'invoice_date')"
                        v-on="inputEvents"
                        placeholder="ลงวันที่"
                      />
                    </template>
                    <template #header-title>
                      {{ headerInputDate }}
                    </template>
                  </v-date-picker>
                </b-form-group>
              </div>

              <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทหลักฐาน:">
                <template v-slot:label> ประเภทหลักฐาน <span class="text-danger" /> </template>
                <v-select
                  v-model="evidence_type"
                  label="evidence"
                  :options="evidenceTypes"
                  placeholder="เลือกประเภทหลักฐาน"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทการขาย:">
                <template v-slot:label> ประเภทการขาย <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    v-model="salesType"
                    :state="errors.length > 0 ? false : null"
                    placeholder="เลือกประเภทการขาย"
                    label="v_gname"
                    :options="salesTypes"
                    class="input-group-merge"
                    @input="cc_salesType"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกประเภทการขาย</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-button v-b-modal.modal-seller> เลือกผู้ขาย </b-button>
              <!-- basic modal -->
              <b-modal id="modal-seller" title="เลือกผู้ขาย" cancel-title="ยกเลิก" ok-title="ตกลง" size="xl">
                <b-form-group class="col-12 col-md-6">
                  <div class="d-flex align-items-center">
                    <label class="mr-1">ค้นหา</label>
                    <b-form-input v-model="searchSeller" placeholder="ค้นหา" type="text" class="d-inline-block" />
                  </div>
                </b-form-group>

                <b-card-text>
                  <vue-good-table
                    :columns="vender_columns"
                    :rows="vender_rows"
                    :search-options="{
                      enabled: true,
                      externalQuery: searchSeller,
                    }"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                      selectionInfoClass: 'custom-class',
                      selectionText: 'rows selected',
                      clearSelectionText: 'clear',
                      disableSelectInfo: true, // disable the select info panel on top
                      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: pageLength_vender,
                    }"
                    @on-row-click="onRowClick"
                  >
                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> Showing 1 to </span>
                          <b-form-select
                            v-model="pageLength_vender"
                            :options="['5', '10', '15', '25', '50', '100']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                          />
                          <span class="text-nowrap"> of {{ total_vender }} entries </span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="total_vender"
                            :per-page="pageLength_vender"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </b-card-text>
              </b-modal>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อผู้ขาย/รับจ้าง:">
                <template v-slot:label> ชื่อผู้ขาย/รับจ้าง <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-input
                    id="seller"
                    v-model="vender"
                    :state="errors.length > 0 ? false : null"
                    placeholder="ชื่อผู้ขาย/รับจ้าง"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="เบอร์โทร:">
                <template v-slot:label> เบอร์โทร <span class="text-danger" /> </template>

                <b-input
                  id="seller"
                  v-model="vender_phone"
                  placeholder="เบอร์โทร"
                  maxlength="10"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="แฟกซ์:">
                <template v-slot:label> แฟกซ์ <span class="text-danger" /> </template>

                <b-input
                  id="seller"
                  v-model="vender_fax"
                  placeholder="แฟกซ์"
                  maxlength="10"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ที่อยู่ผู้ขาย/รับจ้าง:">
                <template v-slot:label> ที่อยู่ผู้ขาย/รับจ้าง <span class="text-danger" /> </template>

                <b-form-textarea
                  id="seller"
                  v-model="vender_address"
                  maxlength="200"
                  placeholder="ที่อยู่ผู้ขาย/รับจ้าง"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="อีเมล:">
                <template v-slot:label> อีเมล <span class="text-danger" /> </template>

                <b-input id="seller" v-model="vender_email" placeholder="อีเมล" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="หมายเลขภาษี:">
                <template v-slot:label> หมายเลขภาษี <span class="text-danger" /> </template>

                <b-input id="seller" v-model="vender_taxnumber" placeholder="หมายเลขภาษี" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="แนบไฟล์:">
                <!-- Styled -->
                <b-form-file class="mb-1" placeholder="เลือกไฟล์แนบ" drop-placeholder="Drop file here..." />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="รูปภาพ:">
                <!-- Styled -->
                <b-form-file
                  accept="image/*"
                  v-model="file1"
                  class="mb-1"
                  placeholder="เลือกไฟล์รูปภาพ"
                  drop-placeholder="Drop file here..."
                  multiple
                />
              </b-form-group>

              <div class="row">
                <span class="ml-2" />
                <span class="ml-5" />
                <b-button variant="success" class="ml-5" @click="passes(arrayPost)"> เพิ่ม </b-button>
                <!-- <span class="ml-5">ผู้สร้าง : <span>test</span></span>
                <span class="ml-5">วันที่ : <span>01/01/2565</span></span>
                <span class="ml-5">ผู้แก้ไข : <span>test</span></span>
                <span class="ml-5">วันที่แก้ไข : <span>01/01/2565</span></span> -->
              </div>
              <div v-for="arrdata in arrdata" :key="arrdata">
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span class="row justify-content-center">
                    <div style="color: #336bff">
                      ชื่อวัสดุ:
                      <span style="color: #000000"> {{ arrdata.m_name }} </span>
                    </div>
                    <div style="color: #336bff">
                      <span> &nbsp;</span> จำนวน:
                      <span style="color: #000000"> {{ arrdata.amount }} {{ arrdata.unittype }} </span>
                    </div>
                    <div style="color: #336bff">
                      <span> &nbsp;</span> ผู้รับเข้า:
                      <span style="color: #000000">
                        {{ arrdata.response_person }}
                      </span>
                    </div>
                  </span>
                </b-list-group-item>
              </div>
            </div>
          </b-form>
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormCheckboxGroup,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BTable,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButton,
  BFormFile,
  BFormSelect,
  BFormDatepicker,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import Cleave from "vue-cleave-component";
import axios from "axios";
import { saveExcel } from "@progress/kendo-vue-excel-export";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import { DatePicker } from "v-calendar";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import API from "@/views/connectDB/condb";
import "swiper/css/swiper.css";

export default {
  components: {
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
    ToastificationContent,
    BImg,
    Swiper,
    SwiperSlide,
    BFormCheckboxGroup,
  },
  data() {
    // const today = new Date(now.getFullYear() + 543, now.getMonth(), now.getDate())
    // // 15th two months prior
    // const minDate = new Date(today)
    // minDate.setMonth(minDate.getMonth())
    // minDate.setDate(now.getDate())
    // // 15th in two months
    // const maxDate = new Date(today)
    // maxDate.setMonth(maxDate.getMonth())
    // maxDate.setDate(now.getDate())
    return {
      unit_price_data: "",
      unit_price_vat_data: "",
      unitprice_data: "",
      unitprice_vat_data: "",

      headerInputDate: "",
      date: new Date(),

      date1: new Date(),
      date2: "",
      date11: new Date(),
      date22: new Date(),
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
      searchSeller: "",
      registration_number: "",
      registration_numbers: [],
      instan: "off",
      vender: "",
      vender_phone: "",
      vender_address: "",
      vender_fax: "",
      vender_email: "",
      vender_taxnumber: "",
      unittype_ss: "",
      unit_price_vat: "",
      attributes: [
        {
          highlight: true,
        },
      ],

      middle: "",
      required,
      statin: null,
      select_vat: "NO VAT",
      vat_options: [
        { text: "รวม VAT", value: "VAT" },
        { text: "ไม่รวม VAT", value: "NO VAT" },
      ],
      disabled: true,
      value: null,
      // min: minDate,
      // max: maxDate,
      API: `${API}`,
      file1: null,
      file0: null,
      show: false,
      total: "",
      vat: "",
      price: null,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      vender_columns: [
        {
          label: "ID",
          field: "v_id",
        },
        {
          label: "ชื่อผู้ขาย/รับจ้าง",
          field: "v_name",
        },
        {
          label: "ที่อยู่ผู้ขาย/รับจ้าง",
          field: "v_address",
        },
        {
          label: "เบอร์โทรผู้ขาย",
          field: "v_telephone",
        },
        {
          label: "แฟกซ์ผู้ขาย",
          field: "v_fax",
        },
        {
          label: "email",
          field: "v_email",
        },
        {
          label: "หมายเลขภาษี",
          field: "v_taxnumber",
        },
      ],
      vender_rows: [],
      total_vender: "",
      pageLength_vender: 15,
      _page_vender: 1,
      QR_image: "",
      m_gid: "",
      m_id: "",
      m_name: "",
      budget_year: "",
      amount: "",
      unittype: "",
      unitprice: "",
      unitprice_vat: "",
      material_code: "",
      admitdate: "",
      supply_by: "",
      budget_type: "",
      evidence_type: "",

      register_name: "รับเข้าวัสดุ",
      important_number: null,
      important_number_date: null,
      delivery_invoice: "",
      delivery_invoice_date: "",
      moneytypes: [],
      procurement: [],
      getarr: [],
      work_gid: "",
      workGs: [],
      pageLength: 100,
      _page_: 1,
      dir: false,
      selectedG: "",
      selectedGs: [],
      typeG: "",
      typeGs: [],
      evidence_type: "",
      evidenceTypes: [
        { value: "", text: "เลือกประเภทหลักฐาน" },
        { value: "ใบส่งของ/ใบกำกับาษี", text: "ใบส่งของ/ใบกำกับาษี" },
      ],
      v_gid: "",
      unit_price: "",
      salesType: "",
      salesTypes: [],
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "admitdate",
        },
        {
          label: "เลขทะเบียน",
          field: "material_code",
        },
        {
          label: "หมายเลขวัสดุ",
          field: "m_id",
        },
        {
          label: "ชื่อวัสดุ",
          field: "m_name",
          thClass: "text-center",
        },
        {
          label: "ผู้รับเข้า",
          field: "response_person",
          thClass: "text-center",
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          formatFn: this.formatFn,
        },
        {
          label: "จำนวน",
          field: "amount",
        },
        {
          label: "ราคารวม VAT",
          field: "unitprice_vat",

          formatFn: this.formatFn,
        },
        {
          label: "ราคาจำนวนเต็มรวม VAT",
          field: "totalprice_vat",

          formatFn: this.formatFn,
        },
        {
          label: "ดูรูปภาพ",
          field: "Image",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
        {
          label: "แก้ไข",
          field: "edit",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
        {
          label: "ลบ",
          field: "del",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
      ],
      rows: [],
      arrdata: [],
      searchTerm: "",
      user: JSON.parse(localStorage.getItem("username")),
      token: "",
      image_p: null,
      units: [],
      alluser: [],
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      material_code_gen: "",
      user_auto: [],
      vat_switch: true,
      footerCellConfig: { background: "#ff0000" },
      work_gid_edit: "",
      middle_edit: "",
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get() {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    this.get_unit();
    this.material_list();
    this.get_data_table();
    this.supplyBy();
    this.s_moneytypes();
    this.s_evidenceTypes();
    this.s_salesTypes();
    this.defaultUserLogin();
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
 
    // this.checkLogout();
    const { access_token } = await this.access_token();
    const url = `${API}materialGroups?_page=1&_limit=100&_sort=1`;
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);
    // console.log(res.data.message.data);
    const res_amp = res.data.message.data.map((element) => ({
      ...element,
      m_gname: `${element.m_gid} - ${element.m_gname}`,
    }));
    this.selectedGs = res_amp;

    const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
    const header_workgroups = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res_workgroups = await axios.get(workgroups, header_workgroups);

    const res_ampa = res_workgroups.data.message.data.map((element) => ({
      ...element,
      work_gname: `${element.work_gid} - ${element.work_gname}`,
    }));

    this.workGs = res_ampa;
  },

  methods: {
    async checkLogout() {
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    perPageChanged(value) {
      console.log(value);
      this.pageLength = value.currentPerPage;
      this.material_list();
    },
    pageChanged(value) {
      console.log(value);
      this._page_ = value.currentPage;
      this.material_list();
    },
    ccc_data() {
      console.log("asdsadsadsadsadsadsadasdsad");
      this.work_gid = "";
      this.middle = "";
      this.amount = "";
      this.unit_price = "";
      this.unitprice = "";
      this.unitprice_vat = "";
      this.unit_price_vat = "";
      this.vat_switch = true;
    },
    get_no_sum_vat(value) {
      if (this.vat_switch === false) {
        // this.unit_price_vat = this.unitprice;
      } else {
        // this.unitPrice_num();
      }
      // console.log(this.unitprice);
      // console.log(this.unit_price_vat);
    },
    async edi_data_row(value) {
      const { access_token } = await this.access_token();
      console.log(value);
      const sp = value.admitdate.split(" ");
      for (let i in this.month) {
        if (this.month[i] == sp[1]) {
          // console.log(i.padStart(2, '0'));
          this.date11 = new Date(Number(sp[2]) - 543 + "-" + i.padStart(2, "0") + "-" + sp[0]).toString();
        }
      }
      console.log(value);
      const workgroups = `${API}workgroups?work_gid=${value.work_gid}&_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      console.log(res_workgroups.data.message.data);
      [this.work_gid] = res_workgroups.data.message.data.map((el) => ({
        ...el,
        work_gname: `${el.work_gid} - ${el.work_gname}`,
      }));
      console.log(this.work_gid);
      const url_user = `${API}user?_page=1&_limit=100`;
      const head_user = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url_user, head_user);

      const res_user = res.data.message.data.map((rs) => ({
        ...rs,
        firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
      }));
      console.log(res_user);

      // this.work_gid_edit = value.work_gid;
      this.middle = res_user.find((el) => el.firstname === value.response_person);
      console.log(this.middle);
      this.date22 = new Date(value.invoice_date).toString();
      // console.log(value.amount);
      // console.log(value.unitprice);
      // console.log(value.unitprice_vat);
      this.amount = value.amount;
      // ราคาจำนวนเต็มรวม
      this.unit_price = value.totalprice;

      this.unitprice = value.unitprice;
      // ราคาจำนวนเต็มรวม VAT
      this.unitprice_vat = value.totalprice_vat;

      this.unit_price_vat = value.unitprice_vat;
      if (this.unitprice == this.unit_price_vat) {
        this.vat_switch = false;
      }
      this.unit_price_vat = Number(this.unit_price_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unitprice_vat = Number(this.unitprice_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unitprice = Number(this.unitprice)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price = Number(this.unit_price)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    async defaultUserLogin() {
      // console.log(this.user);

      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}defaultUserLogin?username=${this.user}`;
      const res = await axios.get(url, head);

      const respost = res.data.message.data;
      // console.log(respost);
      if (respost) {
        this.user_auto = respost;
        this.work_gid = `${respost.work_gid ? respost.work_gid : "เลือกกลุ่มงาน"} - ${
          respost.work_gname ? respost.work_gname : ""
        } `;
        this.middle = respost.fullname;
      }
    },
    async s_data(value) {
      // console.log(value);
      const { access_token } = this.access_token();
      const url = `${API}materialAdmits?material_code=${value}&_limit=100&register_name=รับเข้าวัสดุ`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.rows = res.data.message.data.map((res) => ({
        ...res,
        admitdate: `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]} ${
          Number(res.admitdate.split("-")[0]) + 543
        }`,
        m_image: res.m_image ? JSON.parse(res.m_image) : [],
      }));
    },

    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รับเข้าวัสดุ",
        columns: [
          {
            title: "รับเข้าวัสดุ",

            children: [
              {
                title: "วันที่-เดือน-ปี",
                field: "admitdate",
              },
              {
                title: "เลขทะเบียน",
                field: "material_code",
              },
              {
                title: "หมายเลขวัสดุ",
                field: "m_id",
              },
              {
                title: "ชื่อวัสดุ",
                field: "m_name",
              },

              {
                title: "หน่วยนับ",
                field: "unittype",
              },
              {
                title: "ราคาต่อหน่วย",
                field: "unitprice",
                type: "number",
              },
              {
                title: "จำนวน",
                field: "amount",
              },
              {
                title: "ราคารวม VAT",
                field: "unitprice_vat",
                type: "number",
              },
            ],
          },
        ],
      });
    },
    registration_(value) {
      this.material_code = value;
    },
    async carray_register() {
      // this.checkLogout();
      this.instan = "on";
      this.material_code_gen = "false";
      this.material_code = "";
      this.registration_number = "";
      // materialAdmits

      const url = `${API}materialAdmits?_limit=100&register_name=รับเข้าวัสดุ`;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res);
      console.log(res.data.message);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);

      console.log(res_total);
      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}materialAdmits?register_name=รับเข้าวัสดุ&_page=${i}&_limit=100&`);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      console.log(res_data_);
      const aa = res_data_;
      const b = [];

      const c = [];
      const cc = [];
      for (let i = 0; i < aa.length; i++) {
        // console.log(aa[i].material_code);

        if (b.indexOf(aa[i].material_code) < 0) {
          b.push(aa[i].material_code);
        } else {
          cc.push(aa[i].material_code);
          c.push(aa[i]);
        }
      }
      // console.log(`Array ที่ลบค่าซ้ำ = ${[b]}`);
      // console.log(`Array เฉพาะค่าซ้ำ = ${cc}`);
      this.registration_numbers = b;

      //  console.log(this.registration_numbers)
      return;
      this.registration_numbers = res.data.message.data;
      const pp = [];
      res.data.message.data.forEach((element, index) => {
        pp[index] = element.material_code;
      });

      const bb = [];
      let vv = "";
      let kk = 0;
      res.data.message.data.forEach((element, index) => {
        const aa = pp.indexOf(element.material_code);
        if (aa != vv) {
          bb[kk] = aa;
          kk++;
        }
        vv = aa;
      });
      console.log(bb);
      const bbx = [];
      bb.forEach((element, index) => {
        const aa = res.data.message.data[element];
        bbx[index] = aa;
      });
      console.log(bbx);
      this.registration_numbers = bbx;
    },
    carray() {
      // this.checkLogout();
      this.instan = "off";
      this.material_code_gen = "true";
      this.registration_number = "";
      this.arrdata = [];
      this.material_list();
    },
    async cc_salesType(value) {
      console.log(this.salesType.v_gid);

      const url = `${API}venderInfo?v_gid=${this.salesType.v_gid}&_page=${this._page_vender}&_limit=100`;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      // this.vender_rows = res.data.message.data;

      let res_pdf = [];
      const { total } = res.data.message;
      this.total_vender = total;
      let idx = Math.ceil(Number(total) / 100);
      for (let index = 1; index <= idx; index++) {
        console.log(index);
        const url_pdf = `${API}venderInfo?v_gid=${this.salesType.v_gid}&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, head);
        console.log(res_pdfs.data.message.data);

        res_pdf.push(...res_pdfs.data.message.data);
      }
      console.log(res_pdf);
      this.vender_rows = res_pdf;
    },
    perPageChanged_vender(value) {
      console.log(value);
      this.pageLength_vender = value.currentPerPage;
      this.cc_salesType();
    },
    pageChanged_vender(value) {
      console.log(value);
      this._page_vender = value.currentPage;
      this.cc_salesType();
    },
    async onRowClick(value) {
      // console.log(value.row);
      //  vender: '',
      // vender_phone: '',
      // vender_address: '',
      // vender_fax;
      this.vender = value.row.v_name;
      this.vender_phone = value.row.v_telephone;
      this.vender_address = value.row.v_address;
      this.vender_fax = value.row.v_fax;
      this.vender_email = value.row.v_email;
      this.vender_taxnumber = value.row.v_taxnumber;
    },
    async s_salesTypes() {
      const { access_token } = await this.access_token();
      const url = `${API}vendertype`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const cc = res.data.message.data.map((ss) => ({
        ...ss,
        v_gname: `${ss.v_gid} - ${ss.v_gname}`,
      }));
      this.salesTypes = cc;
    },
    async s_evidenceTypes() {
      // this.evidenceTypes
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.evidenceTypes = res.data.message.data;
    },
    async s_moneytypes() {
      const { access_token } = await this.access_token();
      const url = `${API}moneyTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.moneytypes = res.data.message.data;
    },
    async supplyBy() {
      const { access_token } = await this.access_token();
      const url = `${API}supplyBy`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.procurement = res.data.message.data;
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const res_total = Math.ceil(Number(res.data.message.total) / 100);

      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}user?_page=${i}&_limit=100`);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      console.log(res_data_);

      const res_user = res_data_.map((rs) => ({
        ...rs,
        firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
      }));
      this.alluser = res_user;
    },
    vat_chan() {
      const no_vat_a = (Number(this.unit_price_vat) * 100) / 107;
      console.log(no_vat_a);
      // console.log(no_vat_a.toFixed(2));
      this.unitprice_data = no_vat_a;
      this.unitprice = Number(no_vat_a)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      console.log(this.unitprice);
      this.unit_price = this.unitprice.replace(",", "").replace(",", "").replace(",", "") * this.amount;
      this.unitprice_vat = (Number(this.unit_price) * 7) / 100 + Number(this.unit_price);
      this.unitprice_vat_data = this.unitprice_vat;
      this.unitprice_vat = Number(this.unitprice_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price_data = this.unit_price;
      this.unit_price = Number(this.unit_price)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price_vat_data = this.unit_price_vat;
      this.unit_price_vat = Number(this.unit_price_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    max2string(sum) {
      if (sum) {
        return Number(sum).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      } else {
        return Number(0);
      }
    },
    max_unit_price_vat() {
      this.unit_price_vat =
        Number(this.unitprice_vat.replace(",", "").replace(",", "").replace(",", "")) / Number(this.amount);
      this.unit_price_vat_data = this.unit_price_vat;
      this.unit_price_vat = this.unit_price_vat
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      const gg_a = (Number(this.unit_price_vat.replace(",", "").replace(",", "").replace(",", "")) * 100) / 107;
      this.unitprice_data = gg_a;
      this.unitprice = gg_a
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      this.unit_price = Number(gg_a) * Number(this.amount);
      this.unit_price_data = this.unit_price;
      this.unit_price = this.unit_price
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unitprice_vat_data = this.unitprice_vat;
      this.unitprice_vat = Number(this.unitprice_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    max_unit_price() {
      this.unitprice = Number(this.unit_price.replace(",", "").replace(",", "").replace(",", "")) / Number(this.amount);
      this.unitprice = Number(this.unitprice);
      this.unit_price = Number(this.unit_price);
      this.unit_price_data = this.unit_price;
      this.unit_price = Number(this.unit_price)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      const no_vat_a = this.unitprice;
      const gg_a = (Number(no_vat_a) * 7) / 100;
      this.unit_price_vat = no_vat_a + gg_a;
      console.log(Number(this.unit_price_vat));
      // this.unit_price_vat = this.max2string(this.unit_price_vat);
      // console.log(this.unit_price_vat)
      const no_vat = this.unit_price.replace(",", "").replace(",", "").replace(",", "");
      const gg = (Number(no_vat) * 7) / 100;
      this.unitprice_vat = Number(no_vat) + Number(gg);
      console.log(this.unitprice_vat);
      if (Number(this.unit_price_vat) == "Infinity") {
        this.unit_price_vat = 0;
      } else {
        this.unit_price_vat_data = this.unit_price_vat;
        this.unit_price_vat = Number(this.unit_price_vat)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
      if (this.unitprice === "Infinity") {
        this.unitprice = 0;
      } else {
        this.unitprice_data = this.unitprice;
        this.unitprice = Number(this.unitprice)
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
      this.unitprice_vat_data = this.unitprice_vat;
      this.unitprice_vat = Number(this.unitprice_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    unitPrice_num() {
      // console.log(this.unit_price);
      this.unit_price = this.unitprice.replace(",", "").replace(",", "").replace(",", "") * this.amount;
      this.unitprice = Number(this.unitprice);
      this.unit_price = Number(this.unit_price);
      // console.log(this.unit_price);
      this.unit_price_data = this.unit_price;
      this.unit_price = this.unit_price
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unitprice_data = this.unitprice;
      this.unitprice = this.unitprice
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      const no_vat_a = this.unitprice.replace(",", "").replace(",", "").replace(",", "");
      const gg_a = (Number(no_vat_a) * 7) / 100;
      this.unit_price_vat = Number(no_vat_a) + Number(gg_a);

      const no_vat = this.unit_price.replace(",", "").replace(",", "").replace(",", "");
      const gg = (Number(no_vat) * 7) / 100;
      this.unitprice_vat = Number(no_vat) + Number(gg);
      this.unitprice_vat_data = this.unit_price_vat;
      this.unit_price_vat = Number(this.unit_price_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unitprice_vat_data = this.unitprice_vat;
      this.unitprice_vat = Number(this.unitprice_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    async get_unit() {
      const { access_token } = await this.access_token();
      const url = `${API}unittype?_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.units = res.data.message.data;
    },
    async del(del_data) {
      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `เลขทะเบียน ${del_data.material_code}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}materialAdmits/${del_data.id}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };

          const res = await axios.delete(url, head);

          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ!",
            text: `รายการนี้ ถูกลบเเล้ว ${del_data.material_code}`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.material_list();
        }
      });
    },

    async data_edit(data_edit) {
      console.log(data_edit);
      console.log(this.unitprice);
      console.log(this.unit_price_vat);
      const { access_token } = await this.access_token();

      const url = `${API}materialAdmits/${data_edit.id}`;
      const data = new FormData();
      data.append("budget_year", data_edit.budget_year);

      if (data_edit.unittype.name != undefined) {
        data.append("unittype", data_edit.unittype.name);
      }
      if (data_edit.supply_by.supply != undefined) {
        data.append("supply_by", data_edit.supply_by.supply);
      }
      if (data_edit.budget_type.money_type != undefined) {
        data.append("budget_type", data_edit.budget_type.money_type);
      }
      if (data_edit.evidence_type.evidence != undefined) {
        data.append("evidence_type", `${data_edit.evidence_type.evidence ? data_edit.evidence_type.evidence : " "}`);
      }
      data.append("unitprice", this.unitprice_data.toString());
      data.append(
        "unitprice_vat",
        `${this.vat_switch === false ? this.unitprice_data.toString() : this.unit_price_vat_data.toString()}`
      );
      data.append("admitdate", this.admitdate);
      data.append("important_number", data_edit.important_number);
      data.append("important_date", data_edit.important_date);
      data.append("invoice_number", data_edit.invoice_number);
      data.append(
        "invoice_date",
        `${this.delivery_invoice_date === "undefined-undefined-" ? " " : this.delivery_invoice_date}`
      );
      data.append("vender", data_edit.vender);
      data.append("vender_fax", data_edit.vender_fax);
      data.append("vender_address", data_edit.vender_address);
      data.append("vender_phone", data_edit.vender_phone);
      data.append("vender_email", data_edit.vender_email);
      data.append("vender_taxnumber", data_edit.vender_taxnumber);
      console.log(this.work_gid);
      data.append("work_gid", this.work_gid.work_gid);
      data.append("response_person", this.middle.firstname);

      data.append("totalprice", this.unit_price_data);
      data.append("totalprice_vat", `${this.vat_switch === false ? this.unit_price_data : this.unitprice_vat_data}`);

      if (this.file0 != null) {
        this.file0.forEach((element) => {
          data.append("m_file", element);
        });
      }
      if (this.file1 != null) {
        this.file1.forEach((element) => {
          data.append("m_image", element);
        });
      }

      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, head);
      console.log(res.data.status);
      if (res.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      location.reload();
      this.material_list();
    },
    formatFn(value) {
      return Number(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    arrayPost() {
      if (this.amount.toString() === "0") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `ใส่จำนวนที่ถูกต้อง`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        console.log(this.file1);
        this.arrdata.push({
          m_gid: this.m_gid.m_gid,
          m_id: this.typeG.m_id,
          m_name: this.typeG.m_name,
          budget_year: this.budget_year,
          amount: this.amount,
          unittype: this.unittype.name,
          unitprice: this.unitprice_data,
          unitprice_vat: this.vat_switch === false ? this.unitprice_data : this.unit_price_vat_data,
          work_gid: this.work_gid.work_gid || this.user_auto.work_gid,
          material_code: this.registration_number,
          admitdate: this.admitdate,
          supply_by: this.supply_by.supply,
          budget_type: this.budget_type.money_type,

          register_name: this.register_name,
          m_file: this.file0,
          m_image: this.file1,
          important_number: this.important_number,
          important_date: this.important_number_date,
          invoice_number: this.delivery_invoice,

          vender: this.vender,
          vender_phone: this.vender_phone,
          vender_address: this.vender_address,
          vender_fax: this.vender_fax,
          response_person: this.middle.firstname || this.user_auto.fullname,
          vender_email: this.vender_email || " ",
          vender_taxnumber: this.vender_taxnumber || " ",
          invoice_date: `${this.delivery_invoice_date === "undefined-undefined-" ? " " : this.delivery_invoice_date}`,

          evidence_type: this.evidence_type.evidence || " ",
          totalprice: this.unit_price_data,
          totalprice_vat: `${this.vat_switch === false ? this.unit_price_data : this.unitprice_vat_data}`,
        });
        console.log(this.arrdata);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "เพิ่มรายการรับเข้า",
            variant: "success",
          },
        });
      }
      // console.log(this.work_gid.split('-')[0])

      // console.log(this.work_gid.work_gid)
      // console.log( this.user_auto.work_gid)
    },
    getHeaderInputDate(events) {
      // console.log(events.year);
      const { year, month, day } = events;
      this.datepik = `${year}-${month}-${day}`;
      switch (events.month) {
        case 1:
          this.headerInputDate = `มกราคม ${events.year + 543}`;
          break;
        case 2:
          this.headerInputDate = `กุมภาพันธ์ ${events.year + 543}`;
          break;
        case 3:
          this.headerInputDate = `มีนาคม ${events.year + 543}`;
          break;
        case 4:
          this.headerInputDate = `เมษายน ${events.year + 543}`;
          break;
        case 5:
          this.headerInputDate = `พฤษภาคม ${events.year + 543}`;
          break;
        case 6:
          this.headerInputDate = `มิถุนายน ${events.year + 543}`;
          break;
        case 7:
          this.headerInputDate = `กรกฎาคม ${events.year + 543}`;
          break;
        case 8:
          this.headerInputDate = `สิงหาคม ${events.year + 543}`;
          break;
        case 9:
          this.headerInputDate = `กันยายน ${events.year + 543}`;
          break;
        case 10:
          this.headerInputDate = `ตุลาคม ${events.year + 543}`;
          break;
        case 11:
          this.headerInputDate = `พฤศจิกายน ${events.year + 543}`;
          break;
        case 12:
          this.headerInputDate = `ธันวาคม ${events.year + 543}`;
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      //  console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      // console.log(date);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "admitdate":
            this.admitdate = dete_val;
            break;
          case "invoice_date":
            this.delivery_invoice_date = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      // console.log(this.admitdate);
      return formatDate;
    },
    async img_log(value) {
      const { access_token } = await this.access_token();
      this.token = access_token;
      if (value.m_image.length == 0) {
        this.image_p = null;
      } else {
        this.image_p = value.m_image;
      }

      // console.log(value.m_image);

      // for(let i in value.image){

      //   console.log(image[i])

      // }
      // const { access_token } = await this.access_token();
      // console.log(index);
      // const { data } = await axios.get(
      //   `${API}files/image/${this.rows[index].m_image[0]}`,
      //   { headers: { Authorization: await access_token } }
      // );
      // console.log(access_token);
      // console.log(`${API}files/image/${this.rows[index].m_image[0]}`);
      // console.log(this.rows[index].m_image[0]);

      // const yy = Buffer.from(data, 'base64').toString();
      // this.QR_image = `fetch('${API}files/image/${this.rows[index].m_image[0]}',
      // {headers: {authorization:'${await access_token}'}})
      // .then(r=>r.blob()).then(d=> this.src=window.URL.createObjectURL(d));`;

      // let reader = new FileReader();
      // reader.readAsDataURL(data);
      // reader.onload = () => {
      //   this.data = { type: data.type, image: reader.result };
      //   console.log(this.data);
      // };
    },
    onContext(ctx) {
      console.log(ctx);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รับเข้าวัสดุ",
        columns: [
          {
            title: "รับเข้าวัสดุ",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วันที่-เดือน-ปี",
                field: "admitdate",
              },
              {
                title: "เลขทะเบียน",
                field: "material_code",
              },
              {
                title: "หมายเลขวัสดุ",
                field: "m_id",
              },
              {
                title: "ชื่อวัสดุ",
                field: "m_name",
              },

              {
                title: "หน่วยนับ",
                field: "unittype",
              },
              {
                title: "ราคาต่อหน่วย",
                field: "unitprice_ex",
              },
              {
                title: "จำนวน",
                field: "amount",
              },
              {
                title: "ราคารวม VAT",
                field: "unitprice_vat_ex",
              },
            ],
          },
        ],
      });
    },
    async postdata() {
      console.log(this.arrdata);
      if (this.arrdata.length > 0) {
        console.log("เพิ่ม");
        const mit = this.arrdata.length - 1;
        this.arrdata.forEach(async (element, index) => {
          console.log(element);
          const data = new FormData();
          data.append("m_gid", element.m_gid);
          data.append("m_id", element.m_id);
          data.append("m_name", element.m_name);
          data.append("budget_year", element.budget_year);
          data.append("amount", element.amount);
          data.append("unittype", element.unittype);
          data.append("unitprice", element.unitprice);
          data.append("unitprice_vat", element.unitprice_vat);
          data.append("work_gid", element.work_gid || this.user_auto.work_gid);
          data.append("material_code", element.material_code);
          data.append("admitdate", element.admitdate);
          data.append("supply_by", element.supply_by);
          data.append("budget_type", element.budget_type);
          data.append("evidence_type", element.evidence_type);
          data.append("register_name", element.register_name);
          data.append("m_file", element.file0);
          // element.m_image.forEach((image) => {
          //   data.append('m_image', image);
          // });
          if (element.m_image != null) {
            element.m_image.forEach((image) => {
              console.log(image);
              data.append("m_image", image);
            });
          }
          data.append("important_number", element.important_number);
          data.append("important_date", element.important_date);
          data.append("invoice_number", element.invoice_number);
          data.append("invoice_date", element.invoice_date);
          data.append("vender", element.vender);
          data.append("vender_address", element.vender_address);
          data.append("vender_phone", element.vender_phone);
          data.append("vender_fax", element.vender_fax);
          data.append("response_person", element.response_person);
          data.append("vender_email", element.vender_email);
          data.append("vender_taxnumber", element.vender_taxnumber);
          data.append("material_code_gen", this.material_code_gen);

          data.append("totalprice", element.totalprice);
          data.append("totalprice_vat", element.totalprice_vat);

          // console.log(...data)
          // console.log(element.evidence_type);
          // console.log(element.unitprice);
          // console.log(element.unitprice_vat);
          // return;

          const { access_token } = await this.access_token();
          const url = `${API}materialAdmits`;
          const head = {
            headers: {
              authorization: access_token,
            },
          };
          const res = await axios.post(url, data, head);
          console.log(res);

          this.$bvModal.hide("modal-1");
          // this.$bvModal.hide("modal-1");
          this.material_list();
          this.logUser();

          // axios
          //   .post(`${API}materialAdmits`, data, {
          //     headers: {
          //       authorization: access_token,
          //     },
          //   })
          //   .then((response) => {
          //     console.log(response);
          //     this.$bvModal.hide('modal-1');
          //     this.material_list();
          //     this.logUser();
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: 'Notification',
          //         icon: 'BellIcon',
          //         text: `${error.data.message}`,
          //         variant: 'success',
          //       },
          //     });
          //   });
          if (mit === index) {
            location.reload();
          }
        });

        // Swal.fire({
        //   title: "บันทึกข้อมูลสำเร็จ",
        //   showDenyButton: true,
        //   showCancelButton: false,
        //   confirmButtonText: "ตกลง",
        // }).then((result) => {
        //   /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     location.reload();
        //   }
        // });
        // Swal.fire(
        //   {
        //     title: "Good job",
        //     text: "You clicked the button!",
        //     type: "success",
        //   }.then((result) => {
        //     // Reload the Page
        //     location.reload();
        //   })
        // );
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: `เพิ่มรายการรับเข้า ${this.arrdata.length} รายการ`,
            variant: "success",
          },
        });
        // location.reload();
      } else {
        console.log("asdasdasdsadsa");
        if (this.amount.toString() === "0") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `ใส่จำนวนที่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          // console.log('บันทึก');
          const data = new FormData();
          data.append("m_gid", this.m_gid.m_gid);
          data.append("m_id", this.typeG.m_id);
          data.append("m_name", this.typeG.m_name);
          data.append("budget_year", this.budget_year);
          data.append("amount", this.amount);
          data.append("unittype", this.unittype.name);
          data.append("unitprice", this.unitprice_data);
          data.append("unitprice_vat", this.vat_switch === false ? this.unitprice_data : this.unit_price_vat_data);
          data.append("work_gid", this.work_gid.work_gid || this.user_auto.work_gid);
          data.append("material_code", this.registration_number);
          data.append("admitdate", this.admitdate);
          data.append("supply_by", this.supply_by.supply);
          data.append("budget_type", this.budget_type.money_type);
          data.append("evidence_type", this.evidence_type.evidence || " ");
          data.append("vender", this.vender);
          data.append("register_name", this.register_name);
          if (this.file0 != null) {
            this.file0.forEach((element) => {
              data.append("m_file", element);
            });
          }
          if (this.file1 != null) {
            this.file1.forEach((element) => {
              data.append("m_image", element);
            });
          }
          data.append("important_number", this.important_number || " ");
          data.append("important_date", this.important_number_date || " ");
          data.append("invoice_number", this.delivery_invoice || " ");
          data.append(
            "invoice_date",
            `${this.delivery_invoice_date === "undefined-undefined-" ? " " : this.delivery_invoice_date}`
          );
          data.append("vender_address", this.vender_address);
          data.append("vender_phone", this.vender_phone);
          data.append("vender_fax", this.vender_fax);
          data.append("response_person", this.middle.firstname || this.user_auto.fullname);
          data.append("vender_email", this.vender_email);
          data.append("vender_taxnumber", this.vender_taxnumber);
          data.append("material_code_gen", this.material_code_gen);

          data.append("totalprice", this.unit_price_data);
          data.append(
            "totalprice_vat",
            `${this.vat_switch === false ? this.unit_price_data : this.unitprice_vat_data}`
          );

          for (const value of data) {
            console.log(value);
          }

          const { access_token } = await this.access_token();
          axios
            .post(`${API}materialAdmits`, data, {
              headers: {
                authorization: access_token,
              },
            })
            .then((response) => {
              console.log(response);
              if (response.data.status == 200) {
                this.material_list();
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึก",
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.$bvModal.hide("modal-1");
                this.material_list();
                this.logUser();
                location.reload();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "error",
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    },
    async changeRoute(event) {
      if (_.isEmpty(event)) {
      } else {
        const { access_token } = await this.access_token();
        const { m_gid } = event;
        // console.log(m_gid);
        const url = `${API}materialNames?_page=1&_limit=100&_sort=1&m_gid=${m_gid}`;
        const header = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, header);
        // console.log(res.data.message.total);
        const res_data = res.data.message.total;
        // console.log(res_data);
        let aa = Number(res_data) / 100;
        aa = Number(aa) + 1;
        const get_arr = [];
        let ints = 0;
        // console.log(parseInt(aa));
        aa = parseInt(aa);
        for (let index = 0; index < aa; index++) {
          const url = `${API}materialNames?_page=${index + 1}&_limit=100&_sort=1&m_gid=${m_gid}`;
          const header = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.get(url, header);
          // console.log(res.data.message.data);
          // get_arr.push(res.data.message.data)
          res.data.message.data.map((el) => {
            get_arr[ints] = {
              createdAt: el.createdAt,
              id: el.id,
              m_gid: el.m_gid,
              m_id: el.m_id,
              m_name: el.m_name,
              updatedAt: el.updatedAt,
            };
            ints += 1;
          });
        }
        // console.log(get_arr);
        // aa.forEach(async (element, index) => {

        //   const url = `${API}materialNames?_page=${index + 1}&_limit=100&_sort=1&m_gid=${m_gid}`;
        //   const header = {
        //     headers: {
        //       Authorization: await access_token,
        //     },
        //   };
        //   const res = await axios.get(url, header);
        //   console.log(res);
        // });
        this.typeGs = get_arr.map((element) => ({
          ...element,
          m_name: `${element.m_id} - ${element.m_name}`,
        }));
      }
    },

    async material_list() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}materialAdmits?register_name=รับเข้าวัสดุ&_sort=-1&_page=${this._page_}&_limit=100`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      const { materialCodes } = res.data.message;
      this.material_code = materialCodes?.material_code_mix.replace("-NaN-", "001-");
      const total = res.data.message.total;
      this.total = res.data.message.total;
      let idx = Math.ceil(Number(total) / 100);
      let res_pdf = [];
      for (let index = 1; index <= idx; index++) {
        const url_pdf = `${API}materialAdmits?register_name=รับเข้าวัสดุ&_sort=-1&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, header);
        const { data } = res_pdfs.data.message;
        res_pdf.push(...data);
      }
      const result = res_pdf.map((res) => ({
        ...res,
        admitdate: `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]} ${
          Number(res.admitdate.split("-")[0]) + 543
        }`,
        m_image: res.m_image ? JSON.parse(res.m_image) : [],
        unitprice_ex: Number(res.unitprice),
        unitprice_vat_ex: Number(res.unitprice_vat),
        m_name: res.m_name.split(" - ")[1],
      }));
      this.rows = result;
      this.show = false;
      return;
      axios
        .get(
          `${API}materialAdmits?register_name=รับเข้าวัสดุ&_sort=-1&_page=${this._page_}&_limit=${this.pageLength}`,
          {
            headers: {
              authorization: access_token,
            },
          }
        )
        .then(async (response) => {
          console.log(response);
          const { materialCodes } = response.data.message;
          this.material_code = materialCodes?.material_code_mix.replace("-NaN-", "001-");
          const result = response.data?.message?.data?.map((res) => ({
            ...res,
            admitdate: `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]} ${
              Number(res.admitdate.split("-")[0]) + 543
            }`,
            m_image: res.m_image ? JSON.parse(res.m_image) : [],
            unitprice_ex: Number(res.unitprice),
            unitprice_vat_ex: Number(res.unitprice_vat),
            m_name: res.m_name.split("-")[1],
          }));
          console.log(response.data.message.total);
          this.rows = result;
          this.total = response.data.message.total;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          Swal.fire({
            position: "center",
            icon: "error",
            title: error?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    async logUser() {
      const { access_token } = await this.access_token();
      const url = `${API}addHistoryLogs`;
      const data = {
        username: this.user,
        event_name: `รับเข้ารายการ ${this.material_code} `,
      };
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, head);
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
